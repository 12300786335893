import React, { useState } from "react";
import "./Brands.css";
import { useTranslation } from "react-i18next";
import { Brand, getBrand, getBrands } from "../api/BrandApi";
import { SearchOptions } from "../api/TyreApi";
import { Pricing } from "../api/AuthApi";
import { useEffectOnce } from "react-use";
import { capitalize, compareBrands, compareOptions } from "../Utils";
import { Link } from "react-router-dom";

export function AllBrands({ brands }: { brands: Brand[] }) {
  const { t } = useTranslation("home");

  const [brand, setBrand] = useState<Brand[]>([]);

  useEffectOnce(() => {
    getBrands().then(result => {
      setBrand(result.filter(filter => filter.categoryId !== null));
    });
  });

  const columns = brand.map((brand, index) => (
    <div key={index} className="brandItem">
      <Link to={"/brands/" + brand.id}>
        <img src={brand.logo} alt={capitalize(brand.name)} />
      </Link>
    </div>
  ));
  return (
    <div className="containerLightGreen">
      <h2 className="mt-auto">Toutes les marques de pneus sur PneusFrance.fr</h2>
      <p className="brandDescription">
        Chez PneusFrance.fr, vous pouvez non seulement obtenir un excellent service,
        mais nous garantissons également une offre étendue. Grâce à notre grande
        réseau en Europe, nous pouvons offrir toutes les marques à des prix compétitifs.
        Vous pouvez toujours trouver des marques de pneus premium, ainsi que des marques de pneus écologiques sur notre site.
      </p>
      <div className="flex wrap">{columns}</div>
    </div>
  );
}
