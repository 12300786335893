import React from "react";
import { Brands } from "./components/Brands";
import { Services } from "./components/Services";
import { ToWatch } from "./components/ToWatch";
import { SearchBar } from "./components/SearchBar";
import { Brand } from "./api/BrandApi";
import { Pricing } from "./api/AuthApi";
import { SearchOptions } from "./api/TyreApi";
import { Helmet } from "react-helmet";
import ogImage from "./img/searchImage.jpeg";

export function Home({
  brands,
  pricing,
  options,
}: {
  brands: Brand[];
  pricing: Pricing;
  options: SearchOptions;
}) {

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Acheter des pneus de voiture online en France sur Pneusfrance.fr
        </title>
        <meta name="title" content="Acheter des pneus de voiture online en France sur Pneusfrance.fr" />
        <meta name="description" content="Commandez des pneus online en France de la manière la moins chère et la plus rapide sur Pneusfrance.fr. Profitez d'une livraison GRATUITE chez l'un de nos nombreux partenaires."/>

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://pneusfrance.fr/" />
        <meta property="og:title" content="Acheter des pneus de voiture online en France sur Pneusfrance.fr" />
        <meta property="og:description" content="Commandez des pneus online en France de la manière la moins chère et la plus rapide sur Pneusfrance.fr. Profitez de la livraison GRATUITE par l'un de nos nombreux partenaires. Trouvez votre taille de pneu dans notre large gamme." />
        <meta property="og:image" content={ogImage} />
      </Helmet>
      <SearchBar brands={brands} options={options} />
      <ToWatch brands={brands} pricing={pricing} />
      <Brands brands={brands} />
      <Services />
    </>
  );
}
