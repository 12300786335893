import React, { useState } from "react";
import { Brands } from "./components/Brands";
import { SearchBarSmall } from "./components/SearchBarSmall";
import { Brand } from "./api/BrandApi";
import { getWebPromo, SearchOptions, Tyre } from "./api/TyreApi";
import { TyreToWatch } from "./components/TyreToWatch";
import { getCorrectPrice, isBE, isFR, isNL } from "./Utils";
import { useEffectOnce } from "react-use";
import { isMobile } from "react-device-detect";
import { paginate } from "./Search";
import { Pricing } from "./api/AuthApi";
import { AllBrands } from "./components/AllBrands";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export function BrandsPage({
  brands,
  pricing,
  options,
}: {
  brands: Brand[];
  pricing: Pricing;
  options: SearchOptions;
}) {
  const { t } = useTranslation("general");

  const [promo, setPromo] = useState<Tyre[]>([]);

  useEffectOnce(() => {
    getWebPromo().then(result => {
      setPromo(result);
    });
  });

  const [currentPagePromo, setCurrentPagePromo] = useState(1);
  const pageSize = isMobile ? 1 : 3;

  const getPageDataPromo = () => {
    const paginationData = paginate(promo, currentPagePromo, pageSize);
    return { totalCountPromo: promo.length, dataPromo: paginationData };
  };

  const { totalCountPromo, dataPromo } = getPageDataPromo();

  const pageCountPromo = Math.ceil(totalCountPromo / pageSize);
  const nextPagePromo = (increment: number) => {
    if (
      currentPagePromo + increment <= pageCountPromo &&
      currentPagePromo + increment > 0
    ) {
      setCurrentPagePromo(currentPagePromo + increment);
    } else if (pageCountPromo === currentPagePromo) {
      setCurrentPagePromo(1);
    } else if (increment === -1 && currentPagePromo === 1) {
      setCurrentPagePromo(pageCountPromo);
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <link
          rel="alternate"
          hrefLang={isNL() ? "nl-NL" : isFR() ? "fr-BE" : "nl-BE"}
          href={window.location.href}
        />
        <link rel="canonical" href={window.location.href} />
        <title>
          Toutes les marques de pneus sur PneusFrance.fr
        </title>
        <meta
          name="description"
          content="Achetez des pneus bon marché de toutes marques online. PneusFrance.fr, le spécialiste du pneu pour des pneus de marque et de qualité pour voitures, camionnettes et SUV avec livraison GRATUITE* !"
        />
        <meta property="og:title" content="Toutes les marques de pneus sur PneusFrance.fr" />
        <meta property="og:description" content="Achetez des pneus bon marché de toutes marques online. PneusFrance.fr, le spécialiste du pneu pour des pneus de marque et de qualité pour voitures, camionnettes et SUV avec livraison GRATUITE* !" />
        <meta property="og:url" content={window.location.toString()} />
      </Helmet>
      <SearchBarSmall brands={brands} options={options} />
      <div className="containerTransparant">
        <div className="breadcrumb">
          <Link to="/">Home</Link> &gt; Merken
        </div>
        <h1 className="green mt-4">Fourniture de toutes les marques de pneus</h1>
        {dataPromo.length > 0 ? <h2>Découvrez nos pneus à l'honneur</h2> : null}

        <div className="toWatchContainer">
          <div className="tyreToWatchContainer">
            <div className="arrow">
              {pageCountPromo > 1 ? (
                <div className="arrow-left" onClick={() => nextPagePromo(-1)} />
              ) : null}
            </div>
            {dataPromo.map((tyre, index) => {
              const brand = brands.find(b => b.id === tyre.brandId);

              return (
                <TyreToWatch
                  key={index}
                  noise={tyre.noiseLevelDb}
                  noiseLevel={tyre.noiseClass}
                  height={tyre.height}
                  loadIndex={tyre.loadIndex}
                  radius={tyre.radius}
                  speedIndex={tyre.speedIndex}
                  width={tyre.width}
                  id={tyre.id}
                  title={tyre.name}
                  model={brand?.name || ""}
                  price={getCorrectPrice(tyre, pricing)}
                  car={tyre.type}
                  kind={tyre.season}
                  competitors={[]}
                  logos={[]}
                  wetRoadEfficiency={tyre.wetRoadEfficiency}
                  fuelEfficiency={tyre.fuelEfficiency}
                  brandId={tyre.brandId}
                  image={tyre.image}
                />
              );
            })}

            <div className="arrow">
              {pageCountPromo > 1 ? (
                <div className="arrow-right" onClick={() => nextPagePromo(1)} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <AllBrands brands={brands} />
    </>
  );
}
