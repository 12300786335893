import React, { useEffect, useState } from "react";
import { useParams, Link, Redirect } from "react-router-dom";
import { useEffectOnce, useLocation } from "react-use";
import tyreImg from "./img/tyre.png";
import "./Product.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faMinus } from "@fortawesome/free-solid-svg-icons/faMinus";
import { parseInt } from "lodash";
import { Tyre, getTyre, getTopSale, getTyres } from "./api/TyreApi";
import { Brand } from "./api/BrandApi";
import { Pricing } from "./api/AuthApi";
import {
  capitalize,
  getCorrectFuelImage,
  getCorrectPrice,
  getCorrectWetImage, isFR,
  isNL,
} from "./Utils";
import { useTranslation } from "react-i18next";
import { TyreToWatch } from "./components/TyreToWatch";
import { isMobile, isTablet } from "react-device-detect";
import { paginate } from "./Search";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons/faThumbsUp";
import { faForward } from "@fortawesome/free-solid-svg-icons/faForward";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons/faCreditCard";
import { faTruck } from "@fortawesome/free-solid-svg-icons/faTruck";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons/faExchangeAlt";
import { Loading } from "./Loading";
import shoppingCart from "./img/shopping_cart.png";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { Helmet } from "react-helmet";

export function Product({
  brands,
  addProduct,
  pricing,
  language,
}: {
  addProduct: (product: {
    id: string;
    brand: string;
    name: string;
    price: number;
    count: number;
    image: string | null;
    max: number;
    tyre: Tyre;
  }) => void;
  brands: Brand[];
  pricing: Pricing;
  language: string;
}) {
  const location = useLocation();
  const productId = useParams<{ productId: string }>();
  const [tyre, setTyre] = useState<Tyre>();
  const [count, setCount] = useState(1);
  const [redirectToCart, setRedirectToCart] = useState(false);
  const [topSale, setTopSale] = useState<Tyre[]>([]);
  const [loading, setLoading] = useState(true);
  const [extraProducts, setExtraProducts] = useState<Tyre[]>([]);

  const { t } = useTranslation("general");

  const sendDataToGTM = useGTMDispatch();

  useEffectOnce(() => {
    getTyre(productId.productId).then(result => {
      setTyre(result);

      getTyres(
        1,
        `height=${result.height}&width=${result.width}&diameter=${result.radius}`
      ).then(r => {
        setExtraProducts(r.tyres);

        setLoading(false);
      });
    });

    getTopSale().then(result => {
      setTopSale(result);
    });
  });

  useEffect(() => {
    if (!loading) handleViewItem();
  }, [loading]);

  useEffect(() => {
    if (loading) {
      return;
    }

    setLoading(true);

    getTyre(productId.productId).then(result => {
      setTyre(result);
      setLoading(false);

      getTyres(
        1,
        `height=${result.height}&width=${result.width}&diameter=${result.radius}`
      ).then(r => {
        setExtraProducts(r.tyres);
      });
    });

    getTopSale().then(result => {
      setTopSale(result);
    });
  }, [productId]);

  useEffect(() => {
    if (tyre) {
      setCount(tyre.stock > 4 ? 4 : tyre.stock);
    }
  }, [tyre]);

  const [currentPageTopSale, setCurrentPageTopSale] = useState(1);
  const pageSize = isMobile ? 1 : 3;

  const getPageDataTopSale = () => {
    const paginationData = paginate(
      topSale
        .filter(
          i =>
            i.height === tyre?.height &&
            i.width === tyre?.width &&
            i.radius === tyre?.radius
        )
        .concat(extraProducts),
      currentPageTopSale,
      pageSize
    );
    return {
      totalCountTopSale: topSale
        .filter(
          i =>
            i.height === tyre?.height &&
            i.width === tyre?.width &&
            i.radius === tyre?.radius
        )
        .concat(extraProducts).length,
      dataTopSale: paginationData,
    };
  };

  const { totalCountTopSale, dataTopSale } = getPageDataTopSale();

  const pageCountTopSale = Math.ceil(totalCountTopSale / pageSize);
  const nextPageTopSale = (increment: number) => {
    if (
      currentPageTopSale + increment <= pageCountTopSale &&
      currentPageTopSale + increment > 0
    ) {
      setCurrentPageTopSale(currentPageTopSale + increment);
    } else if (pageCountTopSale === currentPageTopSale) {
      setCurrentPageTopSale(1);
    } else if (increment === -1 && currentPageTopSale === 1) {
      setCurrentPageTopSale(pageCountTopSale);
    }
  };

  const brand = brands.find(b => b.id === tyre?.brandId);

  if (redirectToCart) {
    return <Redirect to={"/cart"} />;
  }

  function TopSaleItems() {
    return (
      <>
        <h1>{t("recommended")}</h1>
        <div className="toWatchContainer no-padding">
          <div className="tyreToWatchContainer">
            <div className="arrow">
              {pageCountTopSale > 1 ? (
                <div
                  className="arrow-left"
                  onClick={() => nextPageTopSale(-1)}
                />
              ) : null}
            </div>
            {dataTopSale.map((tyre, index) => {
              const brand = brands.find(b => b.id === tyre.brandId);

              const isTopSale = topSale.find(t => t.ean === tyre.ean);

              return (
                <TyreToWatch
                  key={index}
                  noise={tyre.noiseLevelDb}
                  noiseLevel={tyre.noiseClass}
                  height={tyre.height}
                  loadIndex={tyre.loadIndex}
                  radius={tyre.radius}
                  speedIndex={tyre.speedIndex}
                  width={tyre.width}
                  id={tyre.id}
                  title={tyre.name}
                  model={brand?.name || ""}
                  price={getCorrectPrice(tyre, pricing)}
                  car={tyre.type}
                  kind={tyre.season}
                  competitors={[]}
                  logos={[]}
                  wetRoadEfficiency={tyre.wetRoadEfficiency}
                  fuelEfficiency={tyre.fuelEfficiency}
                  brandId={tyre.brandId}
                  image={tyre.image}
                  topSale={isTopSale !== undefined}
                />
              );
            })}

            <div className="arrow">
              {pageCountTopSale > 1 ? (
                <div
                  className="arrow-right"
                  onClick={() => nextPageTopSale(1)}
                />
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  }

  const handleAddToCart = () => {
    sendDataToGTM({
      event: "add_to_cart",
      ecommerce: {
        currency: "EUR",
        value: tyre ? getCorrectPrice(tyre, pricing) * count : 0,
        items: [
          {
            item_id: tyre?.ean,
            item_name: tyre?.name,
            item_brand: brand?.name || "",
            quantity: count,
            price: tyre ? getCorrectPrice(tyre, pricing) : 0,
            currency: "EUR",
          },
        ],
      },
    });
  };

  const handleViewItem = () => {
    sendDataToGTM({
      event: "view_item",
      ecommerce: {
        items: [
          {
            item_id: tyre?.ean,
            item_name: tyre?.name,
            currency: "EUR",
            item_brand: brand?.name,
            item_category: tyre?.season,
            price: tyre ? getCorrectPrice(tyre, pricing) : 0,
            quantity: count,
          },
        ],
      },
    });
  };

  return (
    <div className="product-container">
      <Helmet>
        <link
          rel="alternate"
          hrefLang={isNL() ? "nl-NL" : isFR() ? "fr-BE" : "nl-BE"}
          href={window.location.href}
        />
        <title>
          {capitalize(brand?.name || "") +
              " " +
              tyre?.name +
              " " +
              tyre?.width +
              "/" +
              tyre?.height +
              " R" +
              tyre?.radius +
              " bon marché et livraison GRATUITE* chez PneusFrance.fr !"}
        </title>
        <meta charSet={"utf-8"} />
        <link rel="canonical" href={window.location.href} />
        <meta
          name="title"
          content={
            capitalize(brand?.name || "") +
                " " +
                tyre?.name +
                " " +
                tyre?.width +
                "/" +
                tyre?.height +
                " R" +
                tyre?.radius +
                " bon marché et livraison GRATUITE* chez PneusFrance.fr !"
          }
        />
        <meta
          name="description"
          content={capitalize(brand?.name || "") +
                " " +
                tyre?.name +
                " " +
                tyre?.width +
                "/" +
                tyre?.height +
                " R" +
                tyre?.radius +
                " acheter des pneus bon marché online. PneusFrance.fr, le spécialiste du pneu aux Pays-Bas pour des pneus de marque et de qualité pour les voitures, les camionnettes et les SUV avec une livraison GRATUITE* !"
          }
        />

        <meta property="og:type" content="og:product" />
        <meta property="og:url" content={window.location.toString()} />
        <meta
          property="og:title"
          content={capitalize(brand?.name || "") +
                " " +
                tyre?.name +
                " " +
                tyre?.width +
                "/" +
                tyre?.height +
                " R" +
                tyre?.radius +
                " bon marché et livraison GRATUITE* sur PneusFrance.fr !"
          }
        />
        <meta
          property="og:description"
          content={capitalize(brand?.name || "") +
                " " +
                tyre?.name +
                " " +
                tyre?.width +
                "/" +
                tyre?.height +
                " R" +
                tyre?.radius +
                " acheter des pneus bon marché online. PneusFrance.fr, le spécialiste du pneu aux Pays-Bas pour des pneus de marque et de qualité pour les voitures, les camionnettes et les SUV avec une livraison GRATUITE* !"
          }
        />
        <meta property="og:image" content={tyre && tyre.image ? tyre.image : ""} />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            "brand": {
              "@type": "Brand",
              "name": capitalize(brand?.name || ""),
              "image": brand && brand?.logo ? brand?.logo : "",
            },
            "category": tyre && tyre?.season ? tyre.season : "",
            "description": tyre ? "De " + capitalize(brand?.name || "") + tyre?.name + " is een " + t(tyre?.season) + "band met een breedte " + tyre?.width + ", hoogte " + tyre?.height + " en een diameter van " + tyre?.radius + " inches."  : "",
            "gtin13": tyre ? tyre.ean : "",
            "name": capitalize(brand?.name || "") + " " + tyre?.name + " " + tyre?.width + "/" + tyre?.height + " R" + tyre?.radius,
            "image": tyre && tyre.image ? tyre.image : tyreImg,
            "model": tyre ? tyre.name : "",
            "offers":
              {
                "@type": "Offer",
                "availability": "https://schema.org/InStock",
                "price": tyre ? getCorrectPrice(tyre, pricing).toString() : "",
                "priceCurrency": "EUR",
                "eligibleRegion": "FRA"
              }
          })}
        </script>
      </Helmet>

      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="breadcrumb">
            <Link to="/">{t("home")}</Link> &gt; {tyre ? t(tyre.season) : ""}{" "}
            &gt; <Link to={"/brands/" + brand?.id}>{brand?.name}</Link> &gt; {tyre?.name} &gt; {tyre?.width}/
            {tyre?.height} R{tyre?.radius} {tyre?.loadIndex} {tyre?.speedIndex}
          </div>
          <div className="flex product-wrapper">
            <div className="flex flex-column product-info">
              <div className="flex product-info-wrapper">
                <div>
                  <img
                    className="product-img"
                    src={tyre && tyre.image ? tyre.image : tyreImg}
                    alt={
                      brand?.name +
                      " " +
                      tyre?.name +
                      " " +
                      tyre?.width +
                      "/" +
                      tyre?.height +
                      " R" +
                      tyre?.radius +
                      " product photo"
                    }
                  />
                  {tyre && tyre.image ? (
                    ""
                  ) : (
                    <p className="centerAlign">{t("noImage")}</p>
                  )}
                </div>
                <div className="w-100">
                  <div className="flex">
                    <img
                      className="brand-image"
                      src={brand?.logo}
                      alt={brand?.name}
                    />
                    <Link
                      to={`/search${location.search}`}
                      className="button ml-auto"
                    >
                      {t("buttons.backTo")}
                    </Link>
                  </div>

                  <div className="subtitle">{tyre?.name}</div>
                  <div className="type">
                    {tyre?.width}/{tyre?.height} R{tyre?.radius}{" "}
                    {tyre?.loadIndex} {tyre?.speedIndex}
                  </div>
                  <div style={{ fontSize: "13px" }}>{tyre?.ean}</div>
                  <div className="specs">{t("tyre.specifications")}</div>
                  <div className="overflow-auto flex">
                    <table className="table w-100">
                      <tbody>
                        <tr>
                          <td>{t("tyre.season.title")}</td>
                          <td>{tyre ? t(tyre.season) : null}</td>
                        </tr>
                        <tr>
                          <td>{t("tyre.loadIndex")}</td>
                          <td>{tyre?.loadIndex}</td>
                        </tr>
                        <tr>
                          <td>{t("tyre.speedIndex")}</td>
                          <td>{tyre?.speedIndex}</td>
                        </tr>
                        <tr>
                          <td>{t("tyre.tyreLabel")}</td>
                          <td>
                            {" "}
                            {tyre ? (
                              <img
                                className="efficiency"
                                src={getCorrectWetImage(tyre.wetRoadEfficiency)}
                                alt={"road"}
                              />
                            ) : (
                              ""
                            )}{" "}
                            {tyre ? (
                              <img
                                className="efficiency"
                                src={getCorrectFuelImage(tyre.fuelEfficiency)}
                                alt={"fuel"}
                              />
                            ) : (
                              " "
                            )}
                            {tyre?.noiseClass}({tyre?.noiseLevelDb} db)
                          </td>
                        </tr>
                        <tr>
                          <td>{t("tyre.runflat")}</td>
                          <td>{tyre?.runFlat ? "Ja" : "Nee"}</td>
                        </tr>
                        <tr>
                          <td>{t("tyre.vehicle")}</td>
                          <td>{tyre ? t(tyre.type) : null}</td>
                        </tr>
                        <tr>
                          <td>EPRL</td>
                          <td>
                            <a
                              href={tyre?.eprel}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {tyre?.eprel}
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div>
                {tyre?.description === "" ? null : (
                  <>
                    <h1>{t("tyre.description")}</h1>
                    <div>{tyre?.description}</div>
                  </>
                )}
              </div>
              {(!isMobile || isTablet) && topSale.length > 0 ? (
                <TopSaleItems />
              ) : null}
            </div>
            <div className="product-actions">
              <form className="flex flex-column h-100">
                <div className="price">
                  €{tyre ? getCorrectPrice(tyre, pricing) : 0}{" "}
                  <div
                    style={{
                      color: "black",
                      fontSize: "12px",
                      marginLeft: "10px",
                    }}
                  >
                    {t("eachTyre")}
                  </div>
                </div>
                {
                  tyre && tyre.stock > 0 ? (
                    <>
                    <div className="flex items-center">
                      <div
                        className="circle"
                        onClick={e => {
                          e.preventDefault();
                          if (count === 1) {
                            return;
                          }
                          setCount(count - 1);
                        }}
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </div>
                      <input
                        type="number"
                        className="count"
                        min={1}
                        max={tyre?.stock}
                        value={count}
                        onChange={e => {
                          setCount(parseInt(e.target.value));
                        }}
                      />
                      <div
                        className="circle"
                        onClick={e => {
                          e.preventDefault();
                          if (count === tyre?.stock) {
                            return;
                          }

                          setCount(count + 1);
                        }}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </div>
                    </div>
                    {
                      tyre && tyre.stock <= 8 ? (
                        <div>
                          {language === "nl" ? (
                            <>
                              Nog maar {tyre.stock} stuk{tyre.stock === 1 ? "" : "s"}{" "}
                              beschikbaar!
                            </>
                          ) : (
                            <>
                              Il ne reste que {tyre.stock} pièce
                              {tyre.stock === 1 ? "" : "s"} !
                            </>
                          )}
                        </div> )
                        : null
                    }
                    </>
                  ) : null
                }
                <div className="recommendations">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div className="flex">
                            <FontAwesomeIcon
                              className="green mr-4"
                              icon={faCheck}
                            />
                            <FontAwesomeIcon icon={faThumbsUp} />
                          </div>
                        </td>
                        <td>{t("cart.recommendations.recommendation1")}</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="flex">
                            <FontAwesomeIcon
                              className="green mr-4"
                              icon={faCheck}
                            />
                            <FontAwesomeIcon icon={faForward} />
                          </div>
                        </td>
                        <td>{t("cart.recommendations.recommendation2")}</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="flex">
                            <FontAwesomeIcon
                              className="green mr-4"
                              icon={faCheck}
                            />
                            <FontAwesomeIcon icon={faCreditCard} />
                          </div>
                        </td>
                        <td>{t("cart.recommendations.recommendation3")}</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="flex">
                            <FontAwesomeIcon
                              className="green mr-4"
                              icon={faCheck}
                            />
                            <FontAwesomeIcon icon={faTruck} />
                          </div>
                        </td>
                        <td>{t("cart.recommendations.recommendation4")}</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="flex">
                            <FontAwesomeIcon
                              className="green mr-4"
                              icon={faCheck}
                            />
                            <FontAwesomeIcon icon={faExchangeAlt} />
                          </div>
                        </td>
                        <td>{t("cart.recommendations.recommendation6")}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {
                  tyre && tyre.stock > 0 ? (
                    <div
                      className="button flex items-center"
                      onClick={e => {
                        e.preventDefault();

                        if (!tyre) {
                          return;
                        }

                        addProduct({
                          id: productId.productId,
                          name: tyre.name,
                          brand: brand?.name || "",
                          count: count,
                          price: tyre ? getCorrectPrice(tyre, pricing) : 0,
                          image: tyre ? tyre.image : null,
                          max: tyre.stock,
                          tyre: tyre,
                        });

                        handleAddToCart();

                        setRedirectToCart(true);
                      }}
                    >
                      {t("addToCart")}
                      <img
                        className="shoppingCart ml-auto"
                        style={{
                          width: "20px",
                          height: "20px",
                          maxWidth: "20px",
                          maxHeight: "20px",
                        }}
                        src={shoppingCart}
                        alt="Winkelmand"
                      />
                    </div>
                  ) : (
                    <div style={{ width: "300px" }}>
                      Helaas is dit product momenteel uit voorraad. <br />
                      Kom later terug of kijk hieronder bij onze aanbevolen banden voor jou.
                    </div>
                  )
                }
                {isMobile ? <div className="mt-4" /> : null}
                <Link
                  to={`/search${location.search}`}
                  className="button mt-auto"
                >
                  {t("buttons.backTo")}
                </Link>
              </form>
              {isMobile && !isTablet && topSale.length > 0 ? (
                <TopSaleItems />
              ) : null}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
