import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Partner, PublicPartner } from "./api/AuthApi";
import { useParams } from "react-router-dom";
import { getPartnerById, getPublicPartnerById } from "./api/PartnerApi";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import GoogleMapReact from "google-map-react";
import _ from "lodash";

const Marker = ({
  lat,
  lng,
  info,
}: {
  lat: any;
  lng: any;
  info: PublicPartner;
}) => (
  <>
    <div
      style={{
        backgroundColor: "white",
        position: "absolute",
        padding: "8px",
        width: "130px",
        textAlign: "center",
        zIndex: 1000,
      }}
    >
      <div>
        <b>{info.businessName}</b>
      </div>
      <div>
        {info.street} {info.number}
      </div>
      <div>
        {info.zipcode} {info.city}
      </div>
      <div>Tel. {info.phone}</div>
      <div>
        {Object.values(info.placementPrizes).filter(i => i !== 0).length ===
          0 ||
        _.min(Object.values(info.placementPrizes).filter(i => i !== 0)) === 0
          ? null
          : `Montage vanaf: €${_.min(
              Object.values(info.placementPrizes).filter(i => i !== 0)
            )}`}
      </div>
    </div>
    <div
      style={{
        color: "white",
        background: "white",
        border: "6px solid red",
        padding: "5px 5px",
        borderRadius: "100%",
        transform: "translate(-50%, -50%)",
      }}
    />
  </>
);

export default function PartnerPage() {
  const [partner, setPartner] = useState<PublicPartner | undefined>(undefined);
  const [extraServices, setExtraServices] = useState<{ service: string }[]>([]);

  // @ts-ignore
  let { partnerId } = useParams();

  useEffect(() => {
    getPublicPartnerById(partnerId).then(result => {
      setPartner(result);

      const temp = [];

      if (result) {
        if (result.tyreStorage) temp.push({ service: "tyreStorage" });
        if (result.aligning) temp.push({ service: "aligning" });
        if (result.rimCleaning) temp.push({ service: "rimCleaning" });
        if (result.fillNitrogen) temp.push({ service: "fillNitrogen" });
      }

      setExtraServices(temp);
    });
  }, [partnerId]);

  const { t } = useTranslation("general");

  return (
    <>
      <Helmet>
        <title></title>
      </Helmet>

      <div className="containerTransparant">
        <div className="breadcrumb">
          <Link to="/">{t("home")}</Link> &gt;{" "}
          <Link to="/partners">Partners</Link> &gt; {partner?.businessName}
        </div>
        <h1 className="green">
          Banden laten monteren bij {partner?.businessName}
        </h1>
        <div className="w-50">
          <h2>Over {partner?.businessName}</h2>
          <ul>
            <li>
              {partner?.businessName} is een garage in {partner?.city}, waar je
              terecht kan voor de montage van jouw banden.
            </li>
            <li>
              Door jouw banden online te bestellen doe je een groot voordeel.
              Vervolgens kan je deze bij {partner?.businessName} laten monteren.
              Jouw banden worden rechtstreeks bij jouw partner geleverd. Deze
              garage is geverifieerd door het Banden-Online-Kopen-team.
            </li>
          </ul>
        </div>
        {partner && extraServices && extraServices.length > 0 ? (
          <>
            <h2>Overige services</h2>
            <p>
              Bij {partner?.businessName} kan je niet alleen terecht voor de
              montage van jouw banden, maar ook voor:
            </p>
            <ul>
              {extraServices.map(service => {
                return <li>{t(service.service)}</li>;
              })}
            </ul>
          </>
        ) : null}
      </div>
      <div className="h-40">
        {partner !== undefined ? (
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyDuRTBLzqmACIusTyAvCDWf9dY6ZSrr6gU",
            }}
            center={{
              lat: partner?.lat,
              lng: partner?.lon,
            }}
            defaultZoom={14}
          >
            <Marker lat={partner.lat} lng={partner.lon} info={partner} />
          </GoogleMapReact>
        ) : null}
      </div>
    </>
  );
}
