import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { PartnerRegister } from "./PartnerRegister";
import { Home } from "./Home";
import { Login } from "./Login";
import { Dashboard } from "./Dashboard";
import { PartnerLogin } from "./PartnerLogin";
import { Partners } from "./Partners";
import { Product } from "./Product";
import { Cart } from "./Cart";
import { Checkout } from "./Checkout";
import { Search } from "./Search";
import { Brand, getBrands } from "./api/BrandApi";
import { useEffectOnce } from "react-use";
import { Tyre, SearchOptions, getSearchOptions } from "./api/TyreApi";
import { getVatAndPricing, Pricing } from "./api/AuthApi";
import { Success } from "./Success";
import { PasswordReset } from "./PasswordReset";
import { PasswordForgot } from "./PasswordForgot";
import { SummerTyres } from "./SummerTyres";
import { WinterTyres } from "./WinterTyres";
import { AllSeasonTyres } from "./AllSeasonTyres";
import { BrandsPage } from "./BrandsPage";
import { useTranslation } from "react-i18next";
import { BrandPage } from "./BrandPage";
import { Faq } from "./Faq";
import { Terms } from "./Terms";
import { AdminSettings } from "./AdminSettings";
import { AdminTyres } from "./AdminTyres";
import { AdminOrders } from "./AdminOrders";
import { AdminPartners } from "./AdminPartners";
import { AdminTextEditor } from "./AdminTextEditor";
import ScrollToTop from "./ScrollToTop";
import { Contact } from "./Contact";
import { TyreGuide } from "./TyreGuide";
import { DeliveryOptions } from "./DeliveryOptions";
import { AdminMethods } from "./AdminMethods";
import { PaymentMethod } from "./PaymentMethod";
import { Returns } from "./Returns";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import { isBE, isFR, isNL } from "./Utils";
import "./App.css";
import PartnerPage from "./PartnerPage";

export enum LANGUAGE {
  NL = "nl",
  FR = "fr",
}

export type CheckoutData = {
  tyres: {
    tyreId: string;
    amount: number;
  }[];
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  partnerId: string | undefined;
  deliveryStreet: string;
  deliveryNumber: string;
  deliveryZipcode: string;
  deliveryCity: string;
  deliveryCountry: string;
  billingStreet: string;
  billingNumber: string;
  billingZipcode: string;
  billingCity: string;
  billingCountry: string;
  newsLetter: boolean;
};

function App() {
  const gtmParams = { id: "GTM-MT8Z5SP"};
  const [language, setLanguage] = useState(LANGUAGE.FR);
  const [cart, setCart] = useState<
    {
      id: string;
      brand: string;
      name: string;
      count: number;
      price: number;
      max: number;
      image: string | null;
      tyre: Tyre;
    }[]
  >([]);
  const [deliver, setDeliver] = useState(true);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [pricing, setPricing] = useState<Pricing>({
    vat: 21,
    priceType: "BC",
    transportCost: 0,
    recupelC1: 0,
    recupelC2: 0,
  });
  const [searchOptions, setSearchOptions] = useState<SearchOptions>({
    height: [],
    width: [],
    diameter: [],
    season: [],
    vehicleType: [],
    brand: [],
    loadIndex: [],
    speedIndex: [],
    fuelEfficiency: [],
    wetRoadEfficiency: [],
  });
  const [checkoutData, setCheckoutData] = useState<CheckoutData>({
    tyres: [],
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    partnerId: undefined,
    deliveryStreet: "",
    deliveryNumber: "",
    deliveryZipcode: "",
    deliveryCity: "",
    deliveryCountry: "FR",
    billingStreet: "",
    billingNumber: "",
    billingZipcode: "",
    billingCity: "",
    billingCountry: "BE",
    newsLetter: false,
  });
  const [submit, setSubmit] = useState(localStorage.getItem("password"));
  const { i18n } = useTranslation();

  useEffectOnce(() => {
    getBrands().then(result => {
      setBrands(result);
    });

    getVatAndPricing("nl").then(setPricing); // TODO: Check lang

    getSearchOptions("").then(options => {
      setSearchOptions(options);
    });

    const localCart = localStorage.getItem("cart");

    if (localCart) {
      setCart(JSON.parse(localCart));
    }

    const localCheckoutData = localStorage.getItem("checkoutData");

    if (localCheckoutData) {
      setCheckoutData(JSON.parse(localCheckoutData));
    }

    if (isBE() || isNL()) {
      setLanguage(LANGUAGE.NL);
      i18n.changeLanguage("nl").then(() => {});
    }

    if (isFR()) {
      setLanguage(LANGUAGE.FR);
      i18n.changeLanguage("fr").then(() => {});
    }
  });

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  useEffect(() => {
    localStorage.setItem("checkoutData", JSON.stringify(checkoutData));
  }, [checkoutData]);

  return (
    <GTMProvider state={gtmParams}>
      <Router>
        <ScrollToTop />
        <Header language={language} setLanguage={setLanguage} cart={cart} />
        <Switch>
          <Route path="/search">
            <Search brands={brands} pricing={pricing} options={searchOptions} />
          </Route>
          <Route path="/auth/password/:token">
            <PasswordReset />
          </Route>
          <Route path="/auth/password-forgot">
            <PasswordForgot />
          </Route>
          <Route path="/product/:productId">
            <Product
              brands={brands}
              pricing={pricing}
              addProduct={product => {
                const found = cart.find(item => item.id === product.id);

                if (found) {
                  setCart(
                    cart.map(item => {
                      if (item.id === product.id) {
                        return {
                          ...item,
                          count: item.count + product.count,
                        };
                      }

                      return item;
                    })
                  );
                } else {
                  setCart(cart.concat(product));
                }
              }}
              language={language}
            />
          </Route>
          <Route path="/cart">
            <Cart
              cart={cart}
              updateCart={items => {
                setCart(items);
              }}
              deliver={deliver}
              setDeliver={setDeliver}
              brands={brands}
              pricing={pricing}
            />
          </Route>
          <Route path="/checkout">
            <Checkout
              cart={cart}
              deliver={deliver}
              setCheckoutData={setCheckoutData}
            />
          </Route>
          <Route path="/payment">
            <PaymentMethod checkoutData={checkoutData} cart={cart} />
          </Route>
          <Route exact={true} path="/partner-register">
            <PartnerRegister />
          </Route>
          <Route exact={true} path="/partner-login">
            <PartnerLogin />
          </Route>
          <Route exact={true} path="/partners">
            <Partners />
          </Route>
          <Route exact={true} path="/partners/:partnerId">
            <PartnerPage />
          </Route>
          <Route exact={true} path="/login">
            <Login />
          </Route>
          <Route path="/tyres/summer">
            <SummerTyres
              brands={brands}
              pricing={pricing}
              options={searchOptions}
            />
          </Route>
          <Route path="/tyres/winter">
            <WinterTyres
              brands={brands}
              pricing={pricing}
              options={searchOptions}
            />
          </Route>
          <Route path="/tyres/allseason">
            <AllSeasonTyres
              brands={brands}
              pricing={pricing}
              options={searchOptions}
            />
          </Route>
          <Route exact path="/brands">
            <BrandsPage
              brands={brands}
              pricing={pricing}
              options={searchOptions}
            />
          </Route>
          <Route exact path="/brands/:brandId">
            <BrandPage
              brands={brands}
              pricing={pricing}
              options={searchOptions}
              language={language}
            />
          </Route>
          <Route exact={true} path="/dashboard">
            <Dashboard brands={brands} pricing={pricing} />
          </Route>
          <Route exact={true} path="/admin/settings">
            <AdminSettings />
          </Route>
          <Route exact={true} path="/admin/tyres">
            <AdminTyres brands={brands} pricing={pricing} />
          </Route>
          <Route exact={true} path="/admin/orders">
            <AdminOrders brands={brands} />
          </Route>
          <Route exact={true} path="/admin/partners">
            <AdminPartners />
          </Route>
          <Route exact={true} path="/admin/editor">
            <AdminTextEditor brands={brands} setBrands={setBrands} />
          </Route>
          <Route exact={true} path="/admin/methods">
            <AdminMethods />
          </Route>
          <Route exact path="/order/:orderId">
            <Success
              deleteCart={() => {
                localStorage.removeItem("cart");
                setCart([]);
              }}
            />
          </Route>
          <Route path="/faq">
            <Faq />
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>
          <Route path="/contact">
            <Contact brands={brands} options={searchOptions} />
          </Route>
          <Route path="/tyre-guide">
            <TyreGuide />
          </Route>
          <Route path="/delivery-options">
            <DeliveryOptions />
          </Route>
          <Route path="/return-policy">
            <Returns />
          </Route>
          <Route path="/">
            <Home brands={brands} pricing={pricing} options={searchOptions} />
          </Route>
        </Switch>
        <Footer brands={brands} />
      </Router>
    </GTMProvider>
  );
}

export default App;
