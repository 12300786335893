import React from "react";
import { Link, Redirect } from "react-router-dom";
import "./AdminHeader.css";
import { getDecodedToken } from "./api/AuthApi";
import { tokenIsExpired } from "./Utils";

export function AdminHeader() {
  const token = getDecodedToken();

  if (!token || tokenIsExpired(token)) {
    return <Redirect push to={"/partner-login"} />;
  }

  return (
    <div className="admin-header flex justify-between">
      <div>
        <Link to={"/admin/settings"} className="admin-item">
          Settings
        </Link>
        <Link to={"/admin/tyres"} className="admin-item">
          Banden in de kijker
        </Link>
        <Link to={"/admin/orders"} className="admin-item">
          Orders
        </Link>
        <Link to={"/admin/partners"} className="admin-item">
          Partners klanten
        </Link>
        <Link to={"/admin/editor"} className="admin-item">
          Editor
        </Link>
        <Link to={"/admin/methods"} className="admin-item">
          Betaalmethoden
        </Link>
      </div>
      <div>
        <Link
          to={"/partner-login"}
          className="admin-item"
          onClick={() => {
            localStorage.clear();
          }}
        >
          Logout
        </Link>
      </div>
    </div>
  );
}
