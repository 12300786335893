import React, { useState } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import tyre from "./img/tyre.png";
import "./Cart.css";
import { faMinus } from "@fortawesome/free-solid-svg-icons/faMinus";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons/faThumbsUp";
import { faTruck } from "@fortawesome/free-solid-svg-icons/faTruck";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons/faExchangeAlt";
import { Link } from "react-router-dom";
import { getTopSale, getWebPromo, Tyre } from "./api/TyreApi";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { useTranslation } from "react-i18next";
import { TyreToWatch } from "./components/TyreToWatch";
import { getCorrectPrice, isNL } from "./Utils";
import { isMobile } from "react-device-detect";
import { paginate } from "./Search";
import { useEffectOnce } from "react-use";
import { getPersonalInformation, PersonalInformation, Pricing } from "./api/AuthApi";
import { Brand } from "./api/BrandApi";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons/faCreditCard";
import { faForward } from "@fortawesome/free-solid-svg-icons/faForward";
import { Helmet } from "react-helmet";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

export function Cart({
  cart,
  updateCart,
  deliver,
  setDeliver,
  brands,
  pricing,
}: {
  cart: {
    id: string;
    brand: string;
    name: string;
    count: number;
    price: number;
    max: number;
    tyre: Tyre;
    image: string | null;
  }[];
  updateCart: (
    items: {
      id: string;
      brand: string;
      name: string;
      count: number;
      price: number;
      image: string | null;
      max: number;
      tyre: Tyre;
    }[]
  ) => void;
  deliver: boolean;
  setDeliver: (deliver: boolean) => void;
  brands: Brand[];
  pricing: Pricing;
}) {
  const [topSale, setTopSale] = useState<Tyre[]>([]);
  const [promo, setPromo] = useState<Tyre[]>([]);
  const [personalInformation, setPersonalInformation] = useState<PersonalInformation>();

  const { t } = useTranslation("general");

  const sendDataToGTM = useGTMDispatch();

  useEffectOnce(() => {
    getTopSale().then(result => {
      setTopSale(result);
    });

    getWebPromo().then(result => {
      setPromo(result);
    });

    getPersonalInformation().then(result => {
      setPersonalInformation(result);
    });
  });

  const [currentPageTopSale, setCurrentPageTopSale] = useState(1);
  const [currentPagePromo, setCurrentPagePromo] = useState(1);
  const pageSize = isMobile ? 1 : 3;

  const getPageDataTopSale = () => {
    const paginationData = paginate(
      topSale.filter(
        item =>
          cart.map(c => c.tyre.height).includes(item.height) &&
          cart.map(c => c.tyre.width).includes(item.width) &&
          cart.map(c => c.tyre.radius).includes(item.radius)
      ),
      currentPageTopSale,
      pageSize
    );
    return {
      totalCountTopSale: topSale.filter(
        item =>
          cart.map(c => c.tyre.height).includes(item.height) &&
          cart.map(c => c.tyre.width).includes(item.width) &&
          cart.map(c => c.tyre.radius).includes(item.radius)
      ).length,
      dataTopSale: paginationData,
    };
  };

  const { totalCountTopSale, dataTopSale } = getPageDataTopSale();

  const pageCountTopSale = Math.ceil(totalCountTopSale / pageSize);
  const nextPageTopSale = (increment: number) => {
    if (
      currentPageTopSale + increment <= pageCountTopSale &&
      currentPageTopSale + increment > 0
    ) {
      setCurrentPageTopSale(currentPageTopSale + increment);
    } else if (pageCountTopSale === currentPageTopSale) {
      setCurrentPageTopSale(1);
    } else if (increment === -1 && currentPageTopSale === 1) {
      setCurrentPageTopSale(pageCountTopSale);
    }
  };

  const getPageDataPromo = () => {
    const paginationData = paginate(
      promo.filter(
        item =>
          cart.map(c => c.tyre.height).includes(item.height) &&
          cart.map(c => c.tyre.width).includes(item.width) &&
          cart.map(c => c.tyre.radius).includes(item.radius)
      ),
      currentPagePromo,
      pageSize
    );
    return {
      totalCountPromo: promo.filter(
        item =>
          cart.map(c => c.tyre.height).includes(item.height) &&
          cart.map(c => c.tyre.width).includes(item.width) &&
          cart.map(c => c.tyre.radius).includes(item.radius)
      ).length,
      dataPromo: paginationData,
    };
  };

  const { totalCountPromo, dataPromo } = getPageDataPromo();

  const pageCountPromo = Math.ceil(totalCountPromo / pageSize);
  const nextPagePromo = (increment: number) => {
    if (
      currentPagePromo + increment <= pageCountPromo &&
      currentPagePromo + increment > 0
    ) {
      setCurrentPagePromo(currentPagePromo + increment);
    } else if (pageCountPromo === currentPagePromo) {
      setCurrentPagePromo(1);
    } else if (increment === -1 && currentPagePromo === 1) {
      setCurrentPagePromo(pageCountPromo);
    }
  };

  function cartIsNotEmpty() {}

  function TopSaleItems() {
    if (dataTopSale.length === 0) {
      return null;
    }

    return (
      <div className="containerLightGreen">
        <h1 className="mt-auto">{t("recommended")}</h1>
        <div className="toWatchContainer">
          <div className="tyreToWatchContainer">
            <div className="arrow">
              {pageCountTopSale > 1 ? (
                <div
                  className="arrow-left"
                  onClick={() => nextPageTopSale(-1)}
                />
              ) : null}
            </div>
            {dataTopSale.map((tyre, index) => {
              const brand = brands.find(b => b.id === tyre.brandId);

              return (
                <TyreToWatch
                  key={index}
                  height={tyre.height}
                  noise={tyre.noiseLevelDb}
                  noiseLevel={tyre.noiseClass}
                  loadIndex={tyre.loadIndex}
                  radius={tyre.radius}
                  speedIndex={tyre.speedIndex}
                  width={tyre.width}
                  id={tyre.id}
                  title={tyre.name}
                  model={brand?.name || ""}
                  price={getCorrectPrice(tyre, pricing)}
                  car={tyre.type}
                  kind={tyre.season}
                  competitors={[]}
                  logos={[]}
                  wetRoadEfficiency={tyre.wetRoadEfficiency}
                  fuelEfficiency={tyre.fuelEfficiency}
                  brandId={tyre.brandId}
                  image={tyre.image}
                />
              );
            })}

            <div className="arrow">
              {pageCountTopSale > 1 ? (
                <div
                  className="arrow-right"
                  onClick={() => nextPageTopSale(1)}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function PromoItems() {
    if (dataPromo.length === 0) {
      return null;
    }

    return (
      <div className="containerTransparant">
        <h1 className="mt-auto">{t("spotlight")}</h1>
        <div className="toWatchContainer">
          <div className="tyreToWatchContainer">
            <div className="arrow">
              {pageCountPromo > 1 ? (
                <div className="arrow-left" onClick={() => nextPagePromo(-1)} />
              ) : null}
            </div>
            {dataPromo.map((tyre, index) => {
              const brand = brands.find(b => b.id === tyre.brandId);

              return (
                <TyreToWatch
                  key={index}
                  height={tyre.height}
                  noise={tyre.noiseLevelDb}
                  noiseLevel={tyre.noiseClass}
                  loadIndex={tyre.loadIndex}
                  radius={tyre.radius}
                  speedIndex={tyre.speedIndex}
                  width={tyre.width}
                  id={tyre.id}
                  title={tyre.name}
                  model={brand?.name || ""}
                  price={getCorrectPrice(tyre, pricing)}
                  car={tyre.type}
                  kind={tyre.season}
                  competitors={[]}
                  logos={[]}
                  wetRoadEfficiency={tyre.wetRoadEfficiency}
                  fuelEfficiency={tyre.fuelEfficiency}
                  brandId={tyre.brandId}
                  image={tyre.image}
                />
              );
            })}

            <div className="arrow">
              {pageCountPromo > 1 ? (
                <div className="arrow-right" onClick={() => nextPagePromo(1)} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const handleProceedToCheckout = () => {
    sendDataToGTM({
      event: "proceed_to_checkout",
      ecommerce: {
        currency: "EUR",
        value: cart
          .map(item => item.count * item.price)
          .reduce((a, b) => a + b, 0)
          .toFixed(2),
        items: cart.map(item => ({
          item_id: item.id,
          item_name: item.name,
          item_brand: item.brand || "",
          quantity: item.count,
          price: item.price.toFixed(2),
          value: (item.price * item.count).toFixed(2),
          currency: "EUR",
        })),
        delivery: deliver,
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>
          {!isNL()
            ? t("cart.documentTitle")
            : "Uw winkelwagen - Banden-Online-Kopen.nl!"}
        </title>
      </Helmet>
      <div className="cart-container">
        <div className="breadcrumb">
          <Link to="/">{t("home")}</Link> &gt; {t("cart.title")}
        </div>
        <h1 className="partner-header">{t("cart.yourCart")}</h1>
        {cart.length === 0 ? (
          <div className="flex justify-evenly items-center">
            <h2>{t("cart.emptyCart")}</h2>
            <Link to="/" className="button">
              {t("cart.emptySearch")}
            </Link>
          </div>
        ) : null}
        {cart.length > 0 ? (
          <>
            <div className="table-wrapper">
              {isMobile ? (
                <div className="details">
                  {cart.map(item => {
                    return (
                      <div key={item.id}>
                        <div className="name">
                          <b>{item.brand}</b> {item.name}
                        </div>
                        <div className="flex">
                          <div>
                            <img
                              className="product-image"
                              src={item && item.image ? item.image : tyre}
                              alt="tyre"
                            />
                            {item && item.image ? (
                              ""
                            ) : (
                              <div className="centerAlign btw">
                                <small>{t("noImage")}</small>
                              </div>
                            )}
                          </div>
                          <div className="flex justify-center items-center flex-column">
                            <div className="flex items-center justify-center">
                              <div
                                className="circle"
                                onClick={e => {
                                  e.preventDefault();

                                  if (item.count === 1) {
                                    return;
                                  }

                                  updateCart(
                                    cart.map(cartItem => {
                                      if (cartItem.id === item.id) {
                                        return {
                                          ...cartItem,
                                          count: item.count - 1,
                                        };
                                      }

                                      return cartItem;
                                    })
                                  );
                                }}
                              >
                                <FontAwesomeIcon icon={faMinus} />
                              </div>
                              <input
                                type="number"
                                className="count"
                                value={item.count}
                                max={item.max}
                                onChange={e => {
                                  updateCart(
                                    cart.map(cartItem => {
                                      if (cartItem.id === item.id) {
                                        return {
                                          ...cartItem,
                                          count: parseInt(e.target.value),
                                        };
                                      }

                                      return cartItem;
                                    })
                                  );
                                }}
                              />
                              <div
                                className="circle"
                                onClick={e => {
                                  e.preventDefault();

                                  if (item.count === item.max) {
                                    return;
                                  }

                                  updateCart(
                                    cart.map(cartItem => {
                                      if (cartItem.id === item.id) {
                                        return {
                                          ...cartItem,
                                          count: item.count + 1,
                                        };
                                      }

                                      return cartItem;
                                    })
                                  );
                                }}
                              >
                                <FontAwesomeIcon icon={faPlus} />
                              </div>
                            </div>
                            <FontAwesomeIcon
                              className="trash"
                              icon={faTrash}
                              onClick={e => {
                                e.preventDefault();

                                updateCart(
                                  cart.filter(
                                    cartItem => cartItem.id !== item.id
                                  )
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="price"
                          style={{
                            fontSize: "18px",
                            marginBottom: "10px",
                            marginTop: "10px",
                          }}
                        >
                          {item.count}x €{item.price.toFixed(2)} = €
                          {(item.price * item.count).toFixed(2)}
                        </div>
                        <table
                          className="product-table w-100"
                          style={{ marginLeft: "0px" }}
                        >
                          <tbody>
                            <tr>
                              <td>{t("tyre.size")}</td>
                              <td>
                                {item.tyre.width}/{item.tyre.height} R
                                {item.tyre.radius}
                              </td>
                            </tr>
                            <tr>
                              <td>{t("tyre.loadIndex")}</td>
                              <td>{item.tyre.loadIndex}</td>
                            </tr>
                            <tr>
                              <td>{t("tyre.speedIndex")}</td>
                              <td>{item.tyre.speedIndex}</td>
                            </tr>
                            <tr>
                              <td>{t("tyre.season.title")}</td>
                              <td>{item.tyre.season}</td>
                            </tr>
                            <tr>
                              <td>{t("tyre.vehicle")}</td>
                              <td>{item.tyre ? t(item.tyre.type) : null}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <table className="details">
                  <thead>
                    <th>{t("cart.product")}</th>
                    <th className="centerAlign">
                      {t("cart.numberOfProducts")}
                    </th>
                    <th className="centerAlign">{t("cart.price")}</th>
                    <th className="centerAlign">{t("cart.total")}</th>
                    <th />
                  </thead>
                  <tbody>
                    {cart.map(item => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <div>
                              <div className="name">
                                <b>{item.brand}</b> {item.name}
                              </div>
                              <div className="flex">
                                <div>
                                  <img
                                    className="product-image"
                                    src={item && item.image ? item.image : tyre}
                                    alt="tyre"
                                  />
                                  {item && item.image ? (
                                    ""
                                  ) : (
                                    <div className="centerAlign btw">
                                      <small>{t("noImage")}</small>
                                    </div>
                                  )}
                                </div>
                                <table className="product-table w-100">
                                  <tbody>
                                    <tr>
                                      <td>{t("tyre.size")}</td>
                                      <td>
                                        {item.tyre.width}/{item.tyre.height} R
                                        {item.tyre.radius}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{t("tyre.loadIndex")}</td>
                                      <td>{item.tyre.loadIndex}</td>
                                    </tr>
                                    <tr>
                                      <td>{t("tyre.speedIndex")}</td>
                                      <td>{item.tyre.speedIndex}</td>
                                    </tr>
                                    <tr>
                                      <td>{t("tyre.season.title")}</td>
                                      <td>{item.tyre.season}</td>
                                    </tr>
                                    <tr>
                                      <td>{t("tyre.vehicle")}</td>
                                      <td>
                                        {item.tyre ? t(item.tyre.type) : null}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="flex items-center justify-center">
                              <div
                                className="circle"
                                onClick={e => {
                                  e.preventDefault();

                                  if (item.count === 1) {
                                    return;
                                  }

                                  updateCart(
                                    cart.map(cartItem => {
                                      if (cartItem.id === item.id) {
                                        return {
                                          ...cartItem,
                                          count: item.count - 1,
                                        };
                                      }

                                      return cartItem;
                                    })
                                  );
                                }}
                              >
                                <FontAwesomeIcon icon={faMinus} />
                              </div>
                              <input
                                type="number"
                                className="count"
                                value={item.count}
                                max={item.max}
                                onChange={e => {
                                  updateCart(
                                    cart.map(cartItem => {
                                      if (cartItem.id === item.id) {
                                        return {
                                          ...cartItem,
                                          count: parseInt(e.target.value),
                                        };
                                      }

                                      return cartItem;
                                    })
                                  );
                                }}
                              />
                              <div
                                className="circle"
                                onClick={e => {
                                  e.preventDefault();

                                  if (item.count === item.max) {
                                    return;
                                  }

                                  updateCart(
                                    cart.map(cartItem => {
                                      if (cartItem.id === item.id) {
                                        return {
                                          ...cartItem,
                                          count: item.count + 1,
                                        };
                                      }

                                      return cartItem;
                                    })
                                  );
                                }}
                              >
                                <FontAwesomeIcon icon={faPlus} />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="price">
                              €{item.price.toFixed(2)}
                            </div>
                          </td>
                          <td>
                            <div className="price">
                              €{(item.price * item.count).toFixed(2)}
                            </div>
                          </td>
                          <td>
                            <FontAwesomeIcon
                              className="trash"
                              icon={faTrash}
                              size={"2x"}
                              onClick={e => {
                                e.preventDefault();

                                updateCart(
                                  cart.filter(
                                    cartItem => cartItem.id !== item.id
                                  )
                                );
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
            <div className="montage">
              <div>{t("cart.montageHow")}</div>
              <div
                className={`delivery ${deliver ? "active" : ""}`}
                onClick={() => {
                  setDeliver(true);
                }}
              >
                {t("cart.montageAtHome")}
              </div>
              <div
                className={`delivery ${!deliver ? "active" : ""}`}
                onClick={() => {
                  setDeliver(false);
                }}
              >
                {t("cart.montageAtGarage")}
              </div>
            </div>
            <div className={`flex justify-between recommendations-wrapper`}>
              <div className="recommendations">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="flex">
                          <FontAwesomeIcon
                            className="green mr-4"
                            icon={faCheck}
                          />
                          <FontAwesomeIcon icon={faThumbsUp} />
                        </div>
                      </td>
                      <td>{t("cart.recommendations.recommendation1")}</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="flex">
                          <FontAwesomeIcon
                            className="green mr-4"
                            icon={faCheck}
                          />
                          <FontAwesomeIcon icon={faForward} />
                        </div>
                      </td>
                      <td>{t("cart.recommendations.recommendation2")}</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="flex">
                          <FontAwesomeIcon
                            className="green mr-4"
                            icon={faCheck}
                          />
                          <FontAwesomeIcon icon={faCreditCard} />
                        </div>
                      </td>
                      <td>{t("cart.recommendations.recommendation3")}</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="flex">
                          <FontAwesomeIcon
                            className="green mr-4"
                            icon={faCheck}
                          />
                          <FontAwesomeIcon icon={faTruck} />
                        </div>
                      </td>
                      <td>{t("cart.recommendations.recommendation4")}</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="flex">
                          <FontAwesomeIcon
                            className="green mr-4"
                            icon={faCheck}
                          />
                          <FontAwesomeIcon icon={faExchangeAlt} />
                        </div>
                      </td>
                      <td>{t("cart.recommendations.recommendation6")}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="total">
                <table className="total-table">
                  <tbody>
                    <tr>
                      <td>{t("cart.shipment")}</td>
                      <td>
                        <b>{t("cart.free").toUpperCase()}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>{t("cart.vat")} {personalInformation?.vat}%</td>
                      <td>
                        <b>{t("cart.included")}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>{t("cart.total")}:</td>
                      <td>
                        <b>
                          €
                          {cart
                            .map(item => item.count * item.price)
                            .reduce((a, b) => a + b, 0)
                            .toFixed(2)}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {cart.length === 0 ? null : (
                  <Link
                    to="checkout"
                    className="button text-center mt-4"
                    onClick={handleProceedToCheckout}
                  >
                    {t("buttons.continueToCheckout")}
                  </Link>
                )}
              </div>
            </div>
          </>
        ) : null}
      </div>
      <TopSaleItems />
      <PromoItems />
    </>
  );
}
